// Import site utilities.
@import '../../global/utils/init';

.l-wrapper {

  .page-title {
    font-family: var(--font-opensans);
    font-size: 32px;
    font-weight: normal;
    margin-bottom: 40px;
  }

  .l-content {
    margin-top: 10px;

    article {

      .field-name-field-floating-image {
        float: left;
        padding: 0 16px 16px 0;
      }

      .field-name-body {
  
        .field-item {
          margin: 0;
          color: #3c3c3c;
  
          h3 {
            font-size: 18px;
            line-height: 24px;
            color: #466626;
            font-weight: normal;
            margin: 15px 0;

            &:first-child {
              margin-top: 0;
            }
          }
  
          a {
            color: #924406;
  
            &:hover {
              color: #666;
              text-decoration: underline;
            }
          }
  
          table {
            margin: 0;
            margin-top: 20px;

            tr {

              th,
              td {
                background: none;
                border: none;
                padding: 0;
                text-align: start;

                p {
                  color: #000;
                  margin: 0;
                  padding: 5px 0;
                }

                div {
                  margin-bottom: 20px;
                }
              }

              &:nth-child(even) {
                background-color: #cccccc;
              }
            }
          }
        }
      }
  
      .field-name-field-private-files {

        .field-item {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
  
        a {
          color: #924406;
  
          &:hover {
            color: #666;
            text-decoration: underline;
          }
        }
      }
    }

    &.full-width {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@include md {

  .l-wrapper {

    .page-title {
      font-size: 24px;
      margin: 5px 0 10px;
    }

    .l-content {
      margin: 0;

      article {

        .field-name-field-floating-image {
          float: unset;
          margin-top: 10px;
          padding: 0;

          img {
            width: 100%;
          }
        }

        .field-name-body {

          .field-item {
            margin-bottom: 0;

            div {
              margin-left: 20px !important;
            }

            table {
              width: 100% !important;
            }

            ul {

              li {
                margin-left: 20px !important;

                div {
                  margin: 0 !important;
                }
              }
            }

            p {

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }

        .field-name-field-private-files {
          margin: 20px 0 30px;
        }
      }
    }
  }
}
