// Import site utilities.
@import '../../global/utils/init';

.l-header {
  background-color: rgb(34, 79, 150);
  margin: 0 0 1rem;
  padding: 0;

  .l-header-inner {
    line-height: 0;
    min-height: unset;
    margin: 0;
    padding: 0;

    .header__logo {
      float: left;
      margin: 0;
      padding: 0;
      padding-top: 48px;
      max-width: 640px;
    }

    .menu-toggle-button {
      display: none;
      position: absolute;
      top: 25px;
      right: 10px;
    }
  
    .header-menu {
      float: right;
      margin-top: 4px;

      ul {

        li {

          a {
            border-right: 1px solid #fff;
            font-size: 14px;
            padding: 0 10px;
            text-transform: uppercase;
          }

          &:last-child {

            a {
              border-right: none;
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .block-system-main-menu {
    bottom: 0;
    position: absolute;
    right: 0;

    .block-content {
      top: -60px;
      height: 3em;
      right: 0;

      .menu-toggles-processed {
        margin: 0 20px 0 0;
        padding: 0;
        text-align: left;

        li {
          float: left;
          margin-right: 10px;
          // padding: 0 10px 0 0;
          padding: 0;
          list-style-type: none;
          list-style-image: none;
          font-size: 20px;

          a {
            color: #fff;
            text-shadow: 1px 1px 1px #000;
            display: block;
            padding: 7px 5px;
            border-radius: 6px;
            text-decoration: none;
            // font-size: 18px;
            font-weight: normal;

            &:hover {
              background-color: #414e5d;
              box-shadow: 1px 1px 1px #000 inset;
            }
          }
        }

        &.menu-tree {

          a {
            padding: 21px 5px;
          }
        }
      }
    }
  }
}

@include lg {

  .l-header {

    .l-header-inner {
      position: relative;

      .header-menu {
        position: absolute;
        right: 0;
        top: 5px;
      }
    }

    .block-system-main-menu {

      .block-content {

        .menu-toggles-processed {
          margin: 0;

          li {

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@include md {

  .l-header {
    height: 160px;

    .l-header-inner {

      .header__logo {
        max-width: 80%;
        padding-top: 25px;
      }

      .menu-toggle-button {
        display: block;
        z-index: 999;
      }

      .header-menu {
        background-color: rgb(34, 79, 150);
        display: none;
        float: unset;
        margin: 0;
        position: absolute;
        top: 122px;
        width: 100%;

        &.menu-expanded {
          display: block;
          z-index: 999;
          
          ul {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 20px;

            li {
              width: 100%;

              a {
                border: none;
                font-size: 20px;
                padding: 16px 20px;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }

    //bottom menu
    .block-system-main-menu {
      bottom: -40px;
      display: flex;
      justify-content: center;
      left: 0;
      width: 100%;

      .block-content {

        .menu-toggle-button {
          display: none;
        }

        .menu-toggles-processed {
          display: block;
          margin: 0;
          padding: 0;
          

          li {
            border-bottom: none;
            font-size: 16px;
            margin-right: 0;
          }
        }
      }

      &.closed-main-menu {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 370px) {
  
  .l-header {

    .block-system-main-menu {
      bottom: -60px;

      .menu-toggles-processed {

        li {
          font-size: 12px !important;
        }
      }
    }
  }
}
