// Import site utilities.
@import '../../global/utils/init';

.block-views-funding-priority-block {

  .block-title {
    display: none;
  }

  .view-content {
    display: flex;
    justify-content: center;
    gap: 30px;

    .views-row {
      position: relative;
      text-align: center;

      .views-field-field-image {

        img {
          padding: 3px;
          box-shadow: 0 0 3px #444;
          max-width: 100%;
          height: auto;
          border: 1px solid #e7e7e7;
        }
      }

      .views-field-title {

        a {
          color: #1f1f1f;
          font-size: 20px;
          text-decoration: none;
          text-align: center;
        }
      }

      .views-field-edit-node {
        position: absolute;
        top: 25px;
        left: 25px;

        a {
          background: rgba(0, 0, 0, 0.6);
          border-radius: 8px;
          padding: 5px;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

@include lg {

  .block-views-funding-priority-block {

    .view-content {
      flex-direction: column;

      .views-row {

        .views-field-edit-node {
          left: 120px;
        }
      }
    }
  }
}

@include md {

  .block-views-funding-priority-block {

    .view-content {

      .views-row {

        .views-field-edit-node {
          left: 20px;
        }
      }
    }
  }
}
