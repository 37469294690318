// Import site utilities.
@import '../../global/utils/init';

.l-sidebar {

  .block {

    .block-title {
      display: none;
    }

    .block-content {

      ul {
        list-style: none;

        li {
          margin: 0;

          &::marker {
            content: url('../images/arrow.png');
          }

          a {
            color: #924406;
            font-size: 18px;
            text-decoration: none;

            &:hover {
              color: #666;
            }
          }
        }
      }
    }
  }

  .block-search-form {

    .search-block-form {

      .form-item {
        margin: 0 0 8px;

        label {
          font-size: 1.33333em;
        }

        .form-search {
          padding: 3px;
        }
      }

      .form-submit {
        line-height: 2.5;
      }
    }
  }
}
