// Import site utilities.
@import '../../global/utils/init';

.block-block-butler-family-foundation {
  margin: 25px 0 45px;

  .block-content {

    p {
      font-size: 30px;
      line-height: initial;
      margin: 0;
      padding: 0 100px;
      color: #343434;
      text-align: center;
      font-family: var(--font-opensans);
    }
  }
}

@include lg {

  .block-block-butler-family-foundation {
    margin: 15px 0 35px;

    .block-content {

      p {
        font-size: 16px;
        padding: 0;
      }
    }
  }
}
