// Import site utilities.
@import '../../global/utils/init';

.l-footer {
  border-top: none;
  font-family: var(--font-opensans);
  margin: 0;
  margin-top: 50px;
  padding: 0;
  line-height: unset;
  text-align: center;

  // .footer-address {
  //   margin-top: 50px;
  //   text-align: center;
  // }

  // .footer-bottom {
  //   text-align: center;

  //   .block-title {
  //     display: none;
  //   }
  // }

  .block-content {
    margin-bottom: 15px;

    p {
      margin: 0;
      
      a {
        color: #000;
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
}
